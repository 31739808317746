var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"header_top"},[_c('handleback'),_c('div',[_vm._v("充值")]),_c('div',[_vm._v("兑换说明")])],1),_c('div',{staticClass:"tab space_around"},[_c('div',{staticClass:"center btn",class:_vm.index == 1 ? 'active' : '',on:{"click":function($event){_vm.handleindex(1);
    _vm.playAudio();}}},[_c('svg',{staticClass:"icon",attrs:{"t":"1708494611211","viewBox":"0 0 1024 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","p-id":"3334"}},[_c('path',{attrs:{"d":"M1024.0512 701.0304V196.864A196.9664 196.9664 0 0 0 827.136 0H196.864A196.9664 196.9664 0 0 0 0 196.864v630.272A196.9152 196.9152 0 0 0 196.864 1024h630.272a197.12 197.12 0 0 0 193.8432-162.0992c-52.224-22.6304-278.528-120.32-396.4416-176.64-89.7024 108.6976-183.7056 173.9264-325.3248 173.9264s-236.1856-87.2448-224.8192-194.048c7.4752-70.0416 55.552-184.576 264.2944-164.9664 110.08 10.3424 160.4096 30.8736 250.1632 60.5184 23.1936-42.5984 42.496-89.4464 57.1392-139.264H248.064v-39.424h196.9152V311.1424H204.8V267.776h240.128V165.632s2.1504-15.9744 19.8144-15.9744h98.4576V267.776h256v43.4176h-256V381.952h208.8448a805.9904 805.9904 0 0 1-84.8384 212.6848c60.672 22.016 336.7936 106.3936 336.7936 106.3936zM283.5456 791.6032c-149.6576 0-173.312-94.464-165.376-133.9392 7.8336-39.3216 51.2-90.624 134.4-90.624 95.5904 0 181.248 24.4736 284.0576 74.5472-72.192 94.0032-160.9216 150.016-253.0816 150.016z","fill":"#727477","p-id":"3335"}})]),_vm._v(" 支付宝 ")]),_c('div',{staticClass:"center btn",class:_vm.index == 3 ? 'active' : '',on:{"click":function($event){_vm.handleindex(3);
    _vm.playAudio();}}},[_c('svg',{staticClass:"icon",attrs:{"t":"1708494940773","viewBox":"0 0 1228 1024","version":"1.1","xmlns":"http://www.w3.org/2000/svg","p-id":"4192"}},[_c('path',{attrs:{"d":"M83.33333333 305.675v507.69c0 76.365 69.3 138.465 154.575 138.465h746.01c85.185 0 154.53-62.1 154.53-138.465V305.675H83.33333333z m1055.115-80.145v-23.85c0-76.365-69.345-138.51-154.53-138.51H237.90833333C152.63333333 63.17 83.33333333 125.27 83.33333333 201.68v23.85h1055.115z m-264.87 337.185A157.635 157.635 0 0 1 1073.33333333 714.5a157.5 157.5 0 1 1-296.37-74.385l-120.6-135.675-1.035-91.215 85.41 1.98 1.305 67.995 61.92 3.24-2.07 66.825 63.225 0.45 8.46 9zM915.83333333 782a67.5 67.5 0 1 0 0-135 67.5 67.5 0 0 0 0 135z","fill":"#727477","p-id":"4193"}})]),_vm._v("卡密 ")]),_c('div',{ref:"slider",staticClass:"slider",style:({ left: _vm.left + 'px' })})]),(this.index != 3)?_c('div',{staticClass:"grade3 header"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"card center",class:item.isselect ? 'actives' : '',on:{"click":function($event){_vm.handlec(item);
    _vm.playAudio();}}},[_vm._v(" "+_vm._s(item.price)+" ")])}),0):_vm._e(),(this.index != 3)?_c('div',{staticClass:"main space_between"},[_c('div',[_vm._v("支付金额")]),_c('div',[_vm._v(_vm._s(_vm.money)+" 元")])]):_vm._e(),(_vm.index == 3)?_c('div',{staticClass:"kami"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.kami),expression:"kami"}],staticClass:"kami_input",attrs:{"type":"text","placeholder":"请输入卡密"},domProps:{"value":(_vm.kami)},on:{"input":function($event){if($event.target.composing)return;_vm.kami=$event.target.value}}})]):_vm._e(),_c('div',{staticClass:"main_center_bottom"},[_c('div',{class:_vm.select ? 'select' : '',on:{"click":function($event){_vm.handleAgree();
    _vm.playAudio();}}}),_c('div',[_vm._v(" 我 已 满 18 岁，并 阅 读 和 同 意《 用 户 协 议 》、《 隐 私 政 策 》和《 反 洗 钱 协 议 》，承 诺 理 性 消 费。 ")])]),(_vm.index == 3)?_c('div',{staticClass:"bottom center",class:_vm.kami && _vm.select ? 'activess' : '',on:{"click":function($event){_vm.handlePay();
  _vm.playAudio();}}},[_vm._v(" 支付 ")]):_c('div',{staticClass:"bottom center",class:_vm.ready && _vm.select ? 'activess' : '',on:{"click":function($event){_vm.handlePay();
  _vm.playAudio();}}},[_vm._v(" 支付 ")]),_c('div',{staticClass:"footer"},[(_vm.isRealCheck == '0')?_c('div',{staticClass:"footer_x",on:{"click":function($event){_vm.Realnameauthentication = true;
    _vm.playAudio();}}},[_vm._v(" 您还未实名认证，点击立即进行实名认证 "),_c('div',{staticClass:"footer_x_u"})]):_vm._e(),(_vm.isRealCheck == '1')?_c('div',{staticClass:"footer_d"},[_vm._v(" 已实名认证 "),_c('div',{staticClass:"footer_d_u"})]):_vm._e()]),(_vm.Realnameauthentication)?_c('div',{staticClass:"out_II"},[_c('div',{ref:"nameel",staticClass:"out_II_I column animate__animated animate__slideInDown"},[_c('div',{staticClass:"out_II_I_I"},[_vm._v("实名认证")]),_c('div',{staticClass:"out_II_I_II"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Exampleobj.realName),expression:"Exampleobj.realName"}],attrs:{"type":"text","name":"","id":"","placeholder":"请输入您的真实姓名"},domProps:{"value":(_vm.Exampleobj.realName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.Exampleobj, "realName", $event.target.value)}}})]),_c('div',{staticClass:"out_II_I_III"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Exampleobj.idNum),expression:"Exampleobj.idNum"}],attrs:{"type":"text","placeholder":"请输入您的身份证号码"},domProps:{"value":(_vm.Exampleobj.idNum)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.Exampleobj, "idNum", $event.target.value)}}})]),_c('div',{staticClass:"out_II_I_IIII space_around"},[_c('div',{staticClass:"btn_c center btn_click",on:{"click":function($event){_vm.handleconfirmnamequxiao();
        _vm.playAudio();}}},[_vm._v(" 取消 ")]),_c('div',{staticClass:"btn_s center btn_click",on:{"click":function($event){_vm.handleconfirmname();
        _vm.playAudio();}}},[(_vm.onsubmit)?_c('div',[_vm._v("确定")]):_c('div',[_c('i',{staticClass:"el-icon-loading"})])])])])]):_vm._e(),(_vm.wxorder)?_c('div',{staticClass:"out_II"},[_c('div',{ref:"nameel",staticClass:"out_II_I columns animate__animated animate__slideInDown"},[_c('div',{staticClass:"out_II_I_I"},[_vm._v("支付宝")]),_c('div',{staticStyle:{"width":"100%","height":"200px"}}),_vm._m(0),_c('div',[_vm._v("订单生成后,请用支付宝描二维码进行支付")]),_c('div',{staticClass:"out_II_I_IIII space_around"},[_c('div',{staticClass:"btn_c center btn_click",on:{"click":function($event){_vm.handleconfirmnamequxiao();
        _vm.playAudio();}}},[_vm._v(" 取消 ")])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"code"}},[_c('canvas',{attrs:{"id":"canvas"}})])
}]

export { render, staticRenderFns }